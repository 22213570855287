<template id="home">
  <b-container fluid>
    <b-row>
      <b-col md="6" offset-md="3" lg="4" offset-lg="4">
        <div class="card" style="background: none; border: none">
          <div class="card-body">
            <p class="lead text-center"></p>

            <form
              method="post"
              accept-charset="utf-8"
              v-on:submit.prevent="login()"
            >
              <div
                :class="'form-group' + (errors.username ? ' has-danger' : '')"
              >
                <label class="sr-only" for="username">Username</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <b-icon icon="person-fill"></b-icon>
                    </span>
                  </div>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    :class="
                      'form-control' +
                      (errors.username ? ' form-control-danger' : '')
                    "
                    placeholder="Username"
                    v-model="username"
                    required
                  />
                </div>

                <template v-if="errors.username">
                  <div class="form-control-feedback">
                    <strong>{{ errors.username }}</strong>
                  </div>
                </template>
              </div>

              <div
                :class="'form-group' + (errors.password ? ' has-danger' : '')"
              >
                <label class="sr-only" for="password">Password</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <b-icon icon="lock-fill"></b-icon>
                    </span>
                  </div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    :class="
                      'form-control' +
                      (errors.password ? ' form-control-danger' : '')
                    "
                    placeholder="Password"
                    v-model="password"
                    required
                  />
                </div>

                <template v-if="errors.password">
                  <div class="form-control-feedback">
                    <strong>{{ errors.password }}</strong>
                  </div>
                </template>
              </div>

              <!--div class="form-group">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="remember"
                          />
                          Remember Me
                        </label>
                      </div>
                    </div-->

              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  @click.prevent="doLogin"
                >
                  {{ $i18n.t("login") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {
        username: "",
        password: "",
      },
      username: "",
      password: "",
    };
  },
  components: {},
  created() {
    document.body.className = "no-admin";
    var root = this.getRoot();
    if (root.auth.authenticated) {
      root.$router.push("/");
    }
  },
  methods: {
    resetErrors: function () {
      this.errors = {
        username: "",
        password: "",
      };
    },
    getRoot: function () {
      return this.$root.$children[0];
    },
    doLogin: function () {
      this.resetErrors();
      var root = this.getRoot();
      root.auth.login(
        root,
        { username: this.username, password: this.password },
        this.$route.query.redirect,
        this
      );
      if (this.errors == undefined) this.resetErrors();
    },
  },
};
</script>
